<template>
  <loading v-if="$store.state.reserveout.status === 'fetching'"></loading>
  <div v-else>
    <div v-if="ticketoptions" class="background">
      <v-dialog
        v-model="errorDialog"
        max-width="325"
        :timeout="-1"
        overlay-color="backgroundoverlay"
        overlay-opacity="1"
      >
        <v-card style="border-radius: 20px !important" flat class="pa-0">
          <v-toolbar flat dense>
            <v-spacer></v-spacer>
            <v-btn icon @click="errorDialog = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text class="Text-Dark-Text-1---18-Center mt-4">
            {{ errorMessage }}
          </v-card-text>
          <v-container class="text-center">
            <v-row>
              <v-col cols="12">
                <v-btn
                  @click="errorDialog = false"
                  min-height="48"
                  min-width="150"
                  class="gradient white--text Text-Dark-Text-1---18-Center"
                  >{{ $t("profile.ok") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <v-toolbar
        flat
        dense
        class="transparent"
        style="padding-top: env(safe-area-inset-top)"
      >
        <v-btn
          style="z-index: 1; width: 37px; height: 37px; margin-left: -4px"
          class="image-action"
          top
          absolute
          icon
          small
          @click.stop="$router.back()"
          ><img src="@/assets/img/arrow-left.svg" style="margin-left: -4px"
        /></v-btn>
        <v-spacer></v-spacer>
        <v-btn
          style="z-index: 1; width: 37px; height: 37px"
          class="image-action mt-5 mr-2"
          small
          icon
          @click.stop="share()"
          ><img src="@/assets/img/share.svg" style="margin-left: -4px"
        /></v-btn>
        <v-btn
          icon
          width="37"
          height="37"
          style="z-index: 11; margin-right: -1px"
          class="mt-5"
          @click="showLocationDialog = true"
        >
          <v-img
            width="37"
            height="37"
            :src="require('@/assets/img/curved_arrow.svg')"
          ></v-img>
        </v-btn>
      </v-toolbar>
      <v-container class="pt-1 px-4 pb-0 mb-n1 mt-3">
        <v-card class="ticket">
          <div>
            <v-card-text
              class="pt-9 HeadingDarkH3-22Center"
              style="padding-bottom: 30px; word-break: break-word"
            >
              {{
                $t("tickets.optionsFound", {
                  date: date,
                  time: time,
                  covers: covers,
                  header: header,
                })
              }}
            </v-card-text>
            <span class="pl-3 Heading-Dark-H4-18-Left-Align">
              {{ $t("tickets.availableOptions") }}
            </span>
            <div
              v-for="(area, areaindex) in ticketoptions"
              :key="areaindex"
              class="px-3 mt-4"
            >
              <span class="Heading-Dark-H4-18-Center">
                {{ area.areaName }}
              </span>
              <v-divider color="#d0c9d6" />
              <v-layout class="mb-8">
                <v-flex>
                  <v-btn
                    class="time Heading-White-H6-14-Center"
                    :class="
                      timeselected === timeindex && areaselected === areaindex
                        ? 'gradient white--text'
                        : ''
                    "
                    v-for="(time, timeindex) in area.times"
                    :key="`time-${timeindex}`"
                    @click="
                      (timeselected = timeindex), (areaselected = areaindex)
                    "
                  >
                    {{ time }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </div>
            <div class="ticket-tear-line"></div>
            <div class="button-wrapper">
              <v-btn
                class="button gradient white--text Heading-White-H5-16-Center"
                @click="getTicket()"
              >
                <span>
                  {{ $t("tickets.getTicket") }}
                </span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-container>
    </div>
    <div v-else>
      <v-dialog
        v-model="dialog"
        max-width="325"
        :timeout="-1"
        overlay-color="backgroundoverlay"
        overlay-opacity="0.5"
      >
        <v-card style="border-radius: 20px !important" flat class="pa-0">
          <v-toolbar flat dense>
            <v-spacer></v-spacer>
            <v-btn @click="$router.back()" icon
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text
            class="HeadingDarkH3-22Center"
            style="word-break: break-word"
          >
            {{
              $t("tickets.noOptionsFound", {
                date: date,
                time: time,
                covers: covers,
                header: header,
              })
            }}
          </v-card-text>
          <v-container class="text-center">
            <v-row>
              <v-col cols="12">
                <v-btn
                  @click="$router.back()"
                  min-height="48"
                  min-width="150"
                  class="gradient white--text Text-Dark-Text-1---18-Center"
                >
                  {{ $t("tickets.ok") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </div>

    <booking-confirmation
      v-if="bookingConfirmation"
      @done="
        (bookingConfirmation = false),
          $router.safePush({
            name: 'home',
          })
      "
      @modify="modifyBooking()"
      @cancel="cancelDialog = true"
    />

    <cancel-booking-dialog
      v-if="cancelDialog"
      @close="cancelDialog = false"
      @cancelConfirmed="cancelReservation"
    />

    <booking-canceled-dialog
      v-if="bookingCanceledDialog"
      @close="bookingCanceled()"
    />

    <complete-profile
      v-if="completeProfile"
      @close="completeProfile = false"
      @completed="completeProfile = false"
    />

    <please-log-in-dialog
      v-if="pleaseLogInDialog"
      :email="profile.email"
      @close="
        (pleaseLogInDialog = false),
          $router.safePush({
            name: 'loginEmail',
          })
      "
    />
  </div>
</template>

<script>
import find from "lodash/find";
import { mapActions } from "vuex";
import moment from "moment";
import Loading from "@/components/app/common/Loading";
import CompleteProfile from "@/components/profile/CompleteProfile";
import CancelBookingDialog from "@/components/app/bookings/CancelBookingDialog";
import BookingCanceledDialog from "@/components/app/bookings/BookingCanceledDialog";
import BookingConfirmation from "@/components/app/bookings/BookingConfirmation";
import PleaseLogInDialog from "@/components/app/bookings/PleaseLogInDialog";

export default {
  name: "ticketoptions",
  components: {
    Loading,
    CompleteProfile,
    CancelBookingDialog,
    BookingCanceledDialog,
    BookingConfirmation,
    PleaseLogInDialog,
  },
  beforeDestroy() {
    this.bookingConfirmation = false;
    this.$store.commit("reserveout/REMOVE_AVAILABILITY");
  },
  beforeMount() {
    if (this.$router.from.name === "modifyBooking") {
      if (this.$router.action === "back") {
        this.$router.safePush({ name: "home" });
      } else {
        this.modify = true;
      }
    }
  },
  data: () => ({
    dialog: true,
    timeselected: 0,
    areaselected: 0,
    completeProfile: false,
    showError: false,
    cancelDialog: false,
    bookingCanceledDialog: false,
    pleaseLogInDialog: false,
    modify: false,
    errorDialog: false,
  }),
  watch: {
    state(state) {
      if (state === "failure") {
        this.errorDialog = true;
      } else {
        this.errorDialog = false;
      }
    },
  },
  computed: {
    profile() {
      return this.$store.state.profile.data;
    },
    state() {
      return this.$store.state.reserveout.status;
    },
    ticketoptions() {
      let ticketoptions = this.$store.state.reserveout.available;
      if (ticketoptions) {
        for (let i = 0; i < ticketoptions.length; i++) {
          let area = ticketoptions[i];
          let times = [];
          let hour = "00";
          let minute = "00";
          if (area.previous && area.previous.available == "AVAILABLE") {
            hour =
              area.previous.time[0] < 10
                ? "0" + area.previous.time[0]
                : area.previous.time[0];
            minute =
              area.previous.time[1] < 10
                ? "0" + area.previous.time[1]
                : area.previous.time[1];
            times.push(hour + ":" + minute);
          }
          if (area.current && area.current.available == "AVAILABLE") {
            hour =
              area.current.time[0] < 10
                ? "0" + area.current.time[0]
                : area.current.time[0];
            minute =
              area.current.time[1] < 10
                ? "0" + area.current.time[1]
                : area.current.time[1];
            times.push(hour + ":" + minute);
          }
          if (area.next && area.next.available == "AVAILABLE") {
            hour =
              area.next.time[0] < 10
                ? "0" + area.next.time[0]
                : area.next.time[0];
            minute =
              area.next.time[1] < 10
                ? "0" + area.next.time[1]
                : area.next.time[1];
            times.push(hour + ":" + minute);
          }
          ticketoptions[i].times = times;
        }
      }
      return ticketoptions;
    },
    errorMessage() {
      // This forces the error message to be updated when the status changes
      this.$store.state.reserveout.status;
      // Show simple error message if no error message was received.
      if (!this.$store.state.reserveout.error) {
        return this.$i18n.translate("reservation.noOptionsFound", {
          date: moment(this.date).format("YYYY-MM-DD"),
          time: this.selectedTime ? this.selectedTime.time : "",
          covers: this.selectedGuests ? this.selectedGuests.number : "",
          header: this.$route.params.header,
        });
      } else {
        return this.$store.state.reserveout.error;
      }
    },
    date() {
      return moment(this.$store.state.tickets.options.date).format(
        "ddd DD, MM yyyy"
      );
    },
    time() {
      return this.$store.state.tickets.options.time;
    },
    covers() {
      return this.$store.state.tickets.options.numberoftickets;
    },
    header() {
      return this.$store.state.tickets.options.placeName;
    },
    selectedCountry() {
      return this.$store.state.tickets.options.countryId;
    },
    selectedPlace() {
      return this.$store.state.tickets.options.restaurantId;
    },
    selectedArea() {
      return this.ticketoptions[this.areaselected].areaId;
    },
    selectedCovers() {
      return this.covers;
    },
    selectedDate() {
      return moment(this.$store.state.tickets.options.date).format(
        "yyyy-MM-DD"
      );
    },
    selectedTime() {
      return this.ticketoptions[this.areaselected].times[this.timeselected];
    },
    tickettype() {
      return this.$store.state.tickets.options.tickettype;
    },
    bookingConfirmation: {
      get() {
        return this.$store.state.reserveout.bookingConfirmation;
      },
      set(val) {
        this.$store.commit("reserveout/SHOW_BOOKING_CONFIRMATION", val);
      },
    },
  },
  methods: {
    ...mapActions("favorites", ["toggleFavorite"]),
    find,
    async getTicket() {
      // Force use of updated profile data.
      let profile = this.$store.state.profile.data;
      if (this.$store.state.isMiKY && !this.$store.state.session.user) {
        this.$store
          .dispatch("profile/userExists", {
            email: profile.email,
          })
          .then(() => {
            if (this.$store.state.profile.userExists) {
              this.pleaseLogInDialog = true;
            } else {
              this.completeProfile = true;
            }
          });
        return;
      }
      if (profile.firstName && profile.lastName && profile.phone) {
        if (this.tickettype === "reserve-out") {
          await this.$store.dispatch("reserveout/postReservation", {
            email: profile.email,
            name: profile.firstName + " " + profile.lastName,
            phone: profile.phone,
            countryId: this.selectedCountry,
            placeId: this.selectedPlace,
            recommendationId: this.$store.state.recommendations.details.id,
            areaId: this.selectedArea,
            covers: this.selectedCovers,
            date: this.selectedDate,
            time: this.selectedTime,
            waitingList: true,
            promotionId: this.$store.state.tickets.options.ticketId,
            source: this.$store.state.recommendations.details.source,
            visitId: this.modify
              ? this.$store.state.tickets.options.visitId
              : undefined,
            action: this.modify ? "modify" : "reserve",
            city: this.$store.state.recommendations.details.city,
          });
        }
      } else {
        this.completeProfile = true;
      }
    },
    cancelReservation() {
      this.$store.dispatch("reserveout/cancelReservation", {
        visitId: this.$store.state.reserveout.reserved.visitId,
      });
      this.cancelDialog = false;
      this.bookingCanceledDialog = true;
    },
    bookingCanceled() {
      this.bookingCanceledDialog = false;
      this.$router.safePush({ name: "home" });
    },
    modifyBooking() {
      this.bookingConfirmation = false;
      this.$store.commit("reserveout/SET_PARAMS", {
        placeId: this.$store.state.reserveout.placeId,
        covers: this.$store.state.reserveout.covers,
        date: this.$store.state.reserveout.date,
        time: this.$store.state.reserveout.time,
        header: this.$store.state.reserveout.reserved.restaurant.name,
        areaId: this.$store.state.reserveout.reserved.area.id,
        countryId: this.$store.state.reserveout.countryId,
        visitId: this.$store.state.reserveout.reserved.visitId,
        promotionId: this.$store.state.reserveout.promotionId,
      });
      this.$router.safePush({
        name: "modifyBooking",
        params: {
          placeId: this.$store.state.reserveout.placeId,
          visitId: this.$store.state.reserveout.reserved.visitId,
        },
      });
    },
  },
};
</script>
<style scoped>
.background {
  background-image: linear-gradient(to bottom, #e7dcff, #f8f5ff);
  min-height: 100vh;
}
.ticket {
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 13px;
  margin-bottom: 17px;
  padding-bottom: 14px;
  box-shadow: none !important;
  border-radius: 16px !important;
  background-color: rgba(0, 0, 0, 0) !important;
  filter: drop-shadow(1px 3px 5px rgba(168, 182, 200, 0.5));
  position: relative;
  color: black;
  font-size: 16px;
  background-size: 51% 100%;
  background-repeat: no-repeat;
  background-image: radial-gradient(
      circle at 0 calc(100% - 132px),
      rgba(255, 255, 255, 0) 10px,
      #fff 11px
    ),
    radial-gradient(
      circle at 100% calc(100% - 132px),
      rgba(255, 255, 255, 0) 10px,
      #fff 11px
    ) !important;
  background-position: top left, top right;
}
.ticket-tear-line {
  margin-top: 72px;
  border-top: 6px dotted #f1f1f1;
  margin-left: 15px;
  margin-right: 15px;
}
.button-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  margin-top: 74px;
}
.button {
  width: 140px !important;
  height: 36px !important;
  padding: 0px !important;
  margin: 0 8px 6px 10px;
  object-fit: contain;
  border-radius: 16px;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0) !important;
}
.time {
  margin: 6px 9px 4px 0px;
  height: 32px !important;
  width: 73px;
  background-color: white !important;
  border-radius: 12px;
  border: 2px solid #9ea6aa;
  box-shadow: none;
}
</style>
